* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

.wrapper {
    background: url("https://t4.ftcdn.net/jpg/05/37/91/97/360_F_537919759_hSQgQ1J9OqynS9h5b1xufu781cUWF6qC.jpg");
    display: flex;
    align-items: center;
    padding: 0rem 6rem;
    justify-content: space-between;
    height: 5rem;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    position: fixed;
    width: 100%;
    top: 0;
    background-color: rgba(197, 190, 190, 0.194);
    z-index: 5;
    display: none;
}

@media screen and (max-width: 1100px) {

    .wrapper {
        background: url("https://t4.ftcdn.net/jpg/05/37/91/97/360_F_537919759_hSQgQ1J9OqynS9h5b1xufu781cUWF6qC.jpg");
        display: flex;
        align-items: center;
        padding: 0rem 6rem;
        justify-content: space-between;
        height: 5rem;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        position: fixed;
        width: 100%;
        top: 0;
        background-color: rgba(197, 190, 190, 0.194);
        z-index: 5;
    }

    .pages:hover {
        background-color: rgba(197, 190, 190, 0.194);
    }

    img {
        width: 3.7rem;
        height: 3.7rem;
    }

}


@media screen and (max-width: 600px) {

    .wrapper {
        background: url("https://t4.ftcdn.net/jpg/05/37/91/97/360_F_537919759_hSQgQ1J9OqynS9h5b1xufu781cUWF6qC.jpg");
        display: flex;
        align-items: center;
        padding: 0rem 4rem;
        justify-content: space-between;
        height: 5rem;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        position: fixed;
        width: 100%;
        top: 0;
        background-color: rgba(197, 190, 190, 0.194);
        z-index: 5;
    }

    .pages {
        font-family: "Trirong", serif;
        display: flex;
        align-items: center;
        padding-left: 1rem;
        color: #2992e7;
        width: 15rem;
        height: 3rem;
    }

    .pages:hover {
        background-color: rgba(197, 190, 190, 0.194);
    }

    img {
        width: 3.7rem;
        height: 3.7rem;
    }

}


@media screen and (max-width: 350px) {

    .wrapper {
        background: url("https://t4.ftcdn.net/jpg/05/37/91/97/360_F_537919759_hSQgQ1J9OqynS9h5b1xufu781cUWF6qC.jpg");
        display: flex;
        align-items: center;
        padding: 0rem 3rem;
        justify-content: space-between;
        height: 5rem;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        position: fixed;
        width: 100%;
        top: 0;
        background-color: rgba(197, 190, 190, 0.194);
        z-index: 5;
    }

    .pages {
        font-family: "Trirong", serif;
        display: flex;
        align-items: center;
        padding-left: 1rem;
        color: #2992e7;
        width: 15rem;
        height: 3rem;
    }

    .pages:hover {
        background-color: rgba(197, 190, 190, 0.194);
    }

    img {
        width: 3.7rem;
        height: 3.7rem;
    }

}