* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

.mainContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin:5rem 5rem;
}

.section1{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.section1 h2{
    width: 500px;
    text-align: center;
    color: #2992e7;
}

.section1 p{
    width: 650px;
    text-align: center;
    font-size: 1.1rem;
    font-family: "Trirong", serif;
}

.horizontalRuleIcon{
    margin: 2.5rem 0rem;
    color: #2992e7;
    display: flex;
    justify-content: center;
    align-items: center;
}

.section2{
    margin: 5rem 0rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
}

.box{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    width: 20rem;
    height: 30rem;
    padding: 4rem 2rem;
    font-family: "Trirong", serif;
    border-top-left-radius: 3rem !important;
    border-bottom-right-radius: 3rem !important;
    background-color: transparent !important;
}

.box img{
    width: 5rem;
}

.box h3{
    color: #2992e7;
}


@media screen and (width > 1400px) {
    .mainContainer {
        max-width: 1350px;
        margin: 3rem auto;
    }
}


@media screen and (max-width: 820px) {

    .section1 p{
        width: 600px;
        text-align: center;
        font-size: 1.1rem;
        font-family: "Trirong", serif;
    }

}

@media screen and (max-width: 700px) {

    .section1 h2{
        width: 400px;
        text-align: center;
        color: #2992e7;
    }

    .section1 p{
        width: 500px;
        text-align: center;
        font-size: 1.1rem;
        font-family: "Trirong", serif;
    }

}

@media screen and (max-width: 600px) {

    .section1 h2{
        width: 300px;
        text-align: center;
        color: #2992e7;
    }

    .section1 p{
        width: 400px;
        text-align: center;
        font-size: 1.1rem;
        font-family: "Trirong", serif;
    }

}



@media screen and (max-width: 470px) {

    .section1 h2{
        width: 280px;
        text-align: center;
        color: #2992e7;
    }

    .section1 p{
        width: 350px;
        text-align: center;
        font-size: 1.1rem;
        font-family: "Trirong", serif;
    }

}


@media screen and (max-width: 385px) {

    .section1 h2{
        width: 250px;
        text-align: center;
        color: #2992e7;
    }

    .section1 p{
        width: 300px;
        text-align: center;
        font-size: 1.1rem;
        font-family: "Trirong", serif;
    }

    .box{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        width: 20rem;
        height: auto;
        padding: 0;
        font-family: "Trirong", serif;
        border: none;
        background-color: transparent !important;
        box-shadow: none !important;
    }

    .section2{
        margin: 5rem 0rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        gap: 5rem;
    }

}



@media screen and (max-width: 340px) {

    .section1 h2{
        width: 100%;
        text-align: center;
        color: #2992e7;
    }

    .section1 p{
        width: 100%;
        text-align: center;
        font-size: 1.1rem;
        font-family: "Trirong", serif;
    }

    .box{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        width: 100%;
        height: auto;
        padding: 0;
        font-family: "Trirong", serif;
        border: none;
        background-color: transparent !important;
        box-shadow: none !important;
    }

    .mainContainer{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin:5rem 1rem;
    }

}