body {
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: lightgray ;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background:#2992e7;
  border-radius: 5px;
}
