* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

.mainContainer {
    margin-bottom: 12rem;
}

.box {
    height: 8rem;
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.box img {
    margin: auto auto;
    width: 8rem;
    height: auto;
}