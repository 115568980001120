* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

.mainContainer {
    margin: 5rem 0rem 5rem 0rem;
}

.section1 {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    align-items: center;
    font-family: "Trirong", serif;
    text-align: center;
}

.section1 span {
    color: #2992e7;
}

.section1 p {
    font-size: 1.1rem;
    width: 900px;
}

.section1 h1 {
    width: 900px;
}


.section2 {
    height: auto;
    margin: 3rem 0rem;
    padding: 3rem 5rem;
    text-align: center;
    background: url("https://media.istockphoto.com/id/1370858067/vector/neutral-abstract-background-minimal-poster-template-with-diagonal-shapes-calm-design-with.jpg?s=612x612&w=0&k=20&c=18U2vCQ0oVYOi0uBiGUEGWaUWf4dgHKCURaJvRssIGI=")no-repeat center center/cover;
    background-attachment: fixed;
}

.cardWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2rem;
}

.box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5rem !important;
    width: 35rem !important;
    height: 18rem !important;
    padding: 2rem !important;
    background-color: transparent !important;
    font-family: "Trirong", serif;
    font-size: 1.1rem;
}

.box h3 {
    color: #2992e7;
}


@media screen and (width > 1400px) {
    .section1 {
        max-width: 1360px;
        margin: auto;
    }

    .cardWrapper {
        max-width: 1360px;
        margin: auto;
    }
}


@media screen and (max-width:930px) {
    .section1 p {
        font-size: 1.1rem;
        width: 800px;
    }

    .section1 h1 {
        width: 800px;
    }
}


@media screen and (max-width:830px) {
    .section1 p {
        font-size: 1.1rem;
        width: 700px;
    }

    .section1 h1 {
        width: 700px;
    }
}


@media screen and (max-width:730px) {
    .section1 p {
        font-size: 1.1rem;
        width: 600px;
    }

    .section1 h1 {
        width: 600px;
    }
}


@media screen and (max-width:630px) {
    .section1 p {
        font-size: 1.1rem;
        width: 500px;
    }

    .section1 h1 {
        width: 500px;
    }
}

@media screen and (max-width:530px) {
    .section1 {
        margin: 0rem 2rem;
    }

    .section1 p {
        font-size: 1.1rem;
        width: 100%;
    }

    .section1 h1 {
        width: 100%;
    }

    .box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1.5rem !important;
        width: 35rem !important;
        height: auto !important;
        padding: 0rem !important;
        background-color: transparent !important;
        box-shadow: none !important;
        font-family: "Trirong", serif;
        font-size: 1.1rem;
    }

    .cardWrapper {
        gap: 4rem;
    }

    .section2 {
        padding: 3rem 2rem;
    }
}