*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

.mainContainer{

    width: 100%;

}

.section1 h2{
    margin-top: 8rem;
    width: 100%;
    text-align: center;
    color: #2992e7;
}

.section2{
    margin: 2rem 5rem;
    display: flex;
    gap: 1.5rem;
}

.section3{
    display: none;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;  
}

.box{
    display: flex;
    flex-direction: column;
    gap: 1.5rem !important;
    width: 23rem !important;
    height: 26rem !important;
    padding: 2rem !important;
    background-color: transparent !important;
    background: url("https://img.freepik.com/free-vector/blue-white-gradient-abstract-background_53876-60241.jpg");
    font-family: "Trirong", serif;
}

.horizontalRuleIcon{
    margin: 1rem 0rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #2992e7;
}

.logoAndName{
    display: flex;
    gap: 2rem;
    align-items: center;
    margin-bottom: 1rem;
    word-wrap: break-word;
}

.logoAndName img{
    width: 3rem;
    height: 3rem;
}

@media screen and (width > 1400px) {
    .mainContainer {
        max-width: 1350px;
        margin: 3rem auto;
    }
}


@media screen and (max-width: 1280px) {

    .box{
        display: flex;
        flex-direction: column;
        gap: 1.5rem !important;
        width: 21rem !important;
        height: 26rem !important;
        padding: 2rem !important;
        background-color: transparent !important;
        background: url("https://img.freepik.com/free-vector/blue-white-gradient-abstract-background_53876-60241.jpg");
        font-family: "Trirong", serif;
    }
    
}

@media screen and (max-width: 1190px) {

    .box{
        display: flex;
        flex-direction: column;
        gap: 1.5rem !important;
        width: 20rem !important;
        height: 26rem !important;
        padding: 2rem !important;
        background-color: transparent !important;
        background: url("https://img.freepik.com/free-vector/blue-white-gradient-abstract-background_53876-60241.jpg");
        font-family: "Trirong", serif;
    }
    
}

@media screen and (max-width: 1140px) {

    .box{
        display: flex;
        flex-direction: column;
        gap: 1.5rem !important;
        width: 25rem !important;
        height: 22rem !important;
        padding: 2rem !important;
        background-color: transparent !important;
        background: url("https://img.freepik.com/free-vector/blue-white-gradient-abstract-background_53876-60241.jpg");
        font-family: "Trirong", serif;
    }
    
}


@media screen and (max-width: 970px) {

    .box{
        display: flex;
        flex-direction: column;
        gap: 1.5rem !important;
        width: 22rem !important;
        height: 25rem !important;
        padding: 2rem !important;
        background-color: transparent !important;
        background: url("https://img.freepik.com/free-vector/blue-white-gradient-abstract-background_53876-60241.jpg");
        font-family: "Trirong", serif;
    }
    
}


@media screen and (max-width: 880px) {

    .box{
        display: flex;
        flex-direction: column;
        gap: 1.5rem !important;
        width: 20rem !important;
        height: 27rem !important;
        padding: 2rem !important;
        background-color: transparent !important;
        background: url("https://img.freepik.com/free-vector/blue-white-gradient-abstract-background_53876-60241.jpg");
        font-family: "Trirong", serif;
    }
    
}


@media screen and (max-width: 810px) {

    .box{
        display: flex;
        flex-direction: column;
        gap: 1.5rem !important;
        width: 18rem !important;
        height: 30rem !important;
        padding: 2rem !important;
        background-color: transparent !important;
        background: url("https://img.freepik.com/free-vector/blue-white-gradient-abstract-background_53876-60241.jpg");
        font-family: "Trirong", serif;
    }
    
}


@media screen and (max-width: 750px) {

    .box{
        display: flex;
        flex-direction: column;
        gap: 1.5rem !important;
        width: 30rem !important;
        height: 22rem !important;
        padding: 2rem !important;
        background-color: transparent !important;
        background: url("https://img.freepik.com/free-vector/blue-white-gradient-abstract-background_53876-60241.jpg");
        font-family: "Trirong", serif;
    }
    
}


@media screen and (max-width: 625px) {

    .box{
        display: flex;
        flex-direction: column;
        gap: 1.5rem !important;
        width: 25rem !important;
        height: 22rem !important;
        padding: 2rem !important;
        background-color: transparent !important;
        background: url("https://img.freepik.com/free-vector/blue-white-gradient-abstract-background_53876-60241.jpg");
        font-family: "Trirong", serif;
    }
    
}


@media screen and (max-width: 565px) {

    .box{
        display: flex;
        flex-direction: column;
        gap: 1.5rem !important;
        width: 22rem !important;
        height: 25rem !important;
        padding: 2rem !important;
        background-color: transparent !important;
        background: url("https://img.freepik.com/free-vector/blue-white-gradient-abstract-background_53876-60241.jpg");
        font-family: "Trirong", serif;
    }
    
}


@media screen and (max-width: 510px) {

    .box{
        display: flex;
        flex-direction: column;
        gap: 1.5rem !important;
        width: 20rem !important;
        height: 27rem !important;
        padding: 2rem !important;
        background-color: transparent !important;
        background: url("https://img.freepik.com/free-vector/blue-white-gradient-abstract-background_53876-60241.jpg");
        font-family: "Trirong", serif;
    }
    
}


@media screen and (max-width: 490px) {

    .box{
        display: flex;
        flex-direction: column;
        gap: 1.5rem !important;
        width: 18rem !important;
        height: 31rem !important;
        padding: 2rem !important;
        background-color: transparent !important;
        background: url("https://img.freepik.com/free-vector/blue-white-gradient-abstract-background_53876-60241.jpg");
        font-family: "Trirong", serif;
    }
    
}


@media screen and (max-width: 450px) {

    .box{
        display: flex;
        flex-direction: column;
        gap: 1.5rem !important;
        width: 15rem !important;
        height: 38rem !important;
        padding: 2rem !important;
        background-color: transparent !important;
        background: url("https://img.freepik.com/free-vector/blue-white-gradient-abstract-background_53876-60241.jpg");
        font-family: "Trirong", serif;
    }
    
}


@media screen and (max-width: 405px){

    .section2{
        margin: 2rem 2rem;
        display: flex;
        gap: 1.5rem;
    }

    .box{
        display: flex;
        flex-direction: column;
        gap: 1.5rem !important;
        width: 18rem !important;
        height: 30rem !important;
        padding: 2rem !important;
        background-color: transparent !important;
        background: url("https://img.freepik.com/free-vector/blue-white-gradient-abstract-background_53876-60241.jpg");
        font-family: "Trirong", serif;
    }
        
}

@media screen and (max-width: 360px){

    .section2{
        margin: 2rem 2rem;
        display: flex;
        gap: 1.5rem;
    }

    .box{
        display: flex;
        flex-direction: column;
        gap: 1.5rem !important;
        width: 15rem !important;
        height: 38rem !important;
        padding: 2rem !important;
        background-color: transparent !important;
        background: url("https://img.freepik.com/free-vector/blue-white-gradient-abstract-background_53876-60241.jpg");
        font-family: "Trirong", serif;
    }
        
}

@media screen and (max-width: 310px){

    .section2{
        margin: 2rem 2rem;
        display: flex;
        gap: 1.5rem;
    }

    .logoAndName{
        display: flex;
        gap: 1rem;
        align-items: center;
        margin-bottom: 1rem;
        word-break: break-all;
    }
    

    .box{
        display: flex;
        flex-direction: column;
        gap: 1.5rem !important;
        width: 12rem !important;
        height: 52rem !important;
        padding: 2rem !important;
        background-color: transparent !important;
        background: url("https://img.freepik.com/free-vector/blue-white-gradient-abstract-background_53876-60241.jpg");
        font-family: "Trirong", serif;
    }
        
}

@media screen and (max-width: 260px){

    .section2{
        margin: 2rem 2rem;
        display: flex;
        gap: 1.5rem;
    }

    .logoAndName{
        display: flex;
        gap: 1rem;
        align-items: center;
        margin-bottom: 1rem;
        word-break: break-all;
    }
    

    .box{
        display: flex;
        flex-direction: column;
        gap: 1.5rem !important;
        width: 10rem !important;
        height: 72rem !important;
        padding: 2rem !important;
        background-color: transparent !important;
        background: url("https://img.freepik.com/free-vector/blue-white-gradient-abstract-background_53876-60241.jpg");
        font-family: "Trirong", serif;
    }
        
}