* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

.mainContainer {
    margin: 8rem 0rem 8rem 0rem;
    font-family: "Trirong", serif;
}

.section1 {
    margin: 0rem 5rem;
    font-size: 1.1rem;
}

.section2 {
    height: auto;
    margin: 3rem 0rem;
    padding: 3rem 5rem;
    text-align: center;
    background: url("https://media.istockphoto.com/id/1370858067/vector/neutral-abstract-background-minimal-poster-template-with-diagonal-shapes-calm-design-with.jpg?s=612x612&w=0&k=20&c=18U2vCQ0oVYOi0uBiGUEGWaUWf4dgHKCURaJvRssIGI=")no-repeat center center/cover;
    background-attachment: fixed;
}

.cardWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2rem;
}

.box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5rem !important;
    width: 35rem !important;
    height: 18rem !important;
    padding: 2rem !important;
    background-color: transparent !important;
    font-family: "Trirong", serif;
    font-size: 1.1rem;
}

.box h3 {
    color: #2992e7;
}

.box span {
    color: #2992e7;
    font-weight: 700;
}

.box img {
    width: 8rem;
    height: 8rem;
}

.section3 {
    margin: 0rem 12rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    align-items: center;
    border: 2px solid #2992e7;
    border-radius: 3rem;
    height: auto;
    font-family: "Trirong", serif;
    font-size: 1.1rem;
    padding: 3rem;
    text-align: center;
}

.section3 h2 {
    color: black;
}

.section3 span {
    color: #2992e7;
}

.services {
    display: flex;
    flex-direction: row;
}

.serviceName {
    display: flex;
    flex-direction: column;
}

.serviceName span {
    color: black;
    font-weight: normal;
}

@media screen and (width > 1400px) {
    .cardWrapper {
        max-width: 1360px;
        margin: auto;
    }
    .section1{
        max-width: 1360px;
        margin: auto;
    }
    .section3{
        max-width: 1360px;
        margin: auto;
    }
}



@media screen and (max-width: 1000px) {
    .section1 p {
        width: 100%;
        font-size: 1.1rem;
    }
    .section1 h1 {
        width: 100%;
    }
}

@media screen and (max-width: 805px) {
    

    .section3 {
        margin: 0rem 2rem;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        justify-content: center;
        align-items: center;
        border: none;
        border-radius: 3rem;
        height: auto;
        font-family: "Trirong", serif;
        padding: 0rem;
        text-align: center;
    }
}

@media screen and (max-width: 600px) {

    .box {
        box-shadow: none !important;
        width: auto !important;
        padding: 0rem !important;
        height: auto !important;
    }

    .cardWrapper{
        gap: 4rem;
    }

    .section2 {
        padding: 3rem 1rem;
    }
}

@media screen and (max-width: 500px) {
    /* .section1 p {
        width: 100%;
        font-size: 1.1rem;
    }

    .section1 h1 {
        width: 100%;
    } */

    .section1 {
        margin: 0rem 2rem;
    }
}