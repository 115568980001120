* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

.nav {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 8.5vh;
    gap: 3rem;
    list-style: none;
    font-size: 1.3rem;
    font-weight: 450;
}

.link {
    color: #2992e7;
    text-decoration: none;
}

.nav li:hover {
    cursor: pointer;
}

.webLogo {
    width: 4rem;
    height: 4rem;
}

.mainSection {
    background: url("https://t4.ftcdn.net/jpg/05/37/91/97/360_F_537919759_hSQgQ1J9OqynS9h5b1xufu781cUWF6qC.jpg")no-repeat center center/cover;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    position: fixed;
    width: 100%;
    top: 0;
    background-color: rgba(197, 190, 190, 0.194);
    z-index: 5;
}

.wrapper {
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 5rem;
}

@media screen and (width > 1400px) {
    .wrapper {
        max-width: 1360px;
    }
}

@media screen and (max-width: 1100px) {

    .mainSection {
        display: none;
    }

}