* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

.map {
    width: 65rem;
    height: 30rem;
    border: none;
}

.mainContainer {
    display: flex;
    justify-content: center;
    align-items: center; 
    background: url("data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAkGBw8PEhUSDw8VFRUVFR0VFRUVFRUVFRUVFRgfFxUVFRUYHSggGB0lHRUVITEhJSkrLi4uFx8zODMtNygtLisBCgoKBQUFDgUFDisZExkrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrK//AABEIALcBEwMBIgACEQEDEQH/xAAYAAEBAQEBAAAAAAAAAAAAAAAAAQIHA//EABgQAQEBAQEAAAAAAAAAAAAAAAABEfCh/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/EABQRAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhEDEQA/AO2AAAAEFARQEVAFABFRQRQBFEAAAFAEVABQAAAAAAAADUWAIAAqAAAAKAiwAEUAAAABFAAKAFQFEUAAAKAAABAAAAAEAAAAAgCiAsEgAqKACALQAAAEUAAAAARQAAFQBUAAACCAAAEAAoAAKAIAKigCKBQAEUBAUAAEABQAAAAAAABUBKFgAmqAAAKgACggqAKigAAAAAgKigCACoKAIoAAEAAAAAAFgDKLAA7wgBQAAAUEAVFBFoAAAihQAABAFEAFQBRFAAAAAAA0EBFRQAAAAAAFQAUQFQAFQAABQQAAAAAAFEgCiKAAAAAi4AyqUAVAFAgAAAAAAAAAAAAAQAAAAAAAhAAVFAiEAUACCAIFAAACAAqKAgAogCiKAGoCgAAAAAiooAAAigAAAAqKgJqooIIoIqQoKAAAAAAd4ACoAqKgKFQAAFEAFRQEABUUAQBTQAAAFQGdEAU7xFAIUAWoAqACiKAAAABQAAQFAAAAQWgECAAApqAKIA1KMxQecUADQAUASCgAAAAAAGgACgIAAAAoAkAAIoCCgAAAAJoAP//Z") no-repeat center center/cover;
    background-attachment: fixed;
}

@media screen and (max-width: 1150px) {
    .map {
        width: 60rem;
        height: 30rem;
        border: none;
    }    
}

@media screen and (max-width: 1050px) {
    .map {
        width: 50rem;
        height: 30rem;
        border: none;
    }    
}

@media screen and (max-width: 900px) {
    .map {
        width: 40rem;
        height: 30rem;
        border: none;
    }    
}

@media screen and (max-width: 700px) {
    .map {
        width: 30rem;
        height: 30rem;
        border: none;
    }    
}

@media screen and (max-width: 600px) {
    .map {
        width: 20rem;
        height: 30rem;
        border: none;
    }    
}

@media screen and (max-width: 400px) {
    .map {
        width: 100%;
        height: 30rem;
        border: none;
    }    
}