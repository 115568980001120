* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

.section1 {
    margin: 8rem 5rem 0rem 5rem;
    font-family: "Trirong", serif;
}

.section2 {
    height: auto;
    margin: 3rem 0rem;
    padding: 3rem 5rem;
    text-align: center;
    background: url("https://media.istockphoto.com/id/1370858067/vector/neutral-abstract-background-minimal-poster-template-with-diagonal-shapes-calm-design-with.jpg?s=612x612&w=0&k=20&c=18U2vCQ0oVYOi0uBiGUEGWaUWf4dgHKCURaJvRssIGI=")no-repeat center center/cover;
    background-attachment: fixed;
}

.cardWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2rem;
}

.box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5rem !important;
    width: 22rem !important;
    height: 24rem !important;
    padding: 2rem !important;
    background-color: transparent !important;
    font-family: "Trirong", serif;
    font-size: 1.1rem;
}

.box h3 {
    color: #2992e7;
}

@media screen and (width > 1400px) {
    .section1 {
        max-width: 1360px;
        margin: 8rem auto auto auto;
    }

    .cardWrapper {
        max-width: 1360px;
        margin: auto;
    }
}

@media screen and (max-width: 500px) {
    .box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1.5rem !important;
        width: 100% !important;
        height: 24rem !important;
        padding: 2rem !important;
        background-color: transparent !important;
        font-family: "Trirong", serif;
        font-size: 1.1rem;
    }

    .section2 {
        padding: 3rem 2rem;
    }

    .section1 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        text-align: center;
        margin: 8rem 2rem 0rem 2rem;
    }
}

@media screen and (max-width: 400px) {
    .box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1.5rem !important;
        width: 100% !important;
        height: auto !important;
        padding: 0rem !important;
        background-color: transparent !important;
        box-shadow: none !important;
        font-family: "Trirong", serif;
        font-size: 1.1rem;
    }

    .cardWrapper {
        gap: 3rem;
    }

    .section2 {
        padding: 3rem 2rem;
    }

    .section1 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        text-align: center;
        margin: 8rem 2rem 0rem 2rem;
    }
}