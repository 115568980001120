*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

.mainContainer{
    margin: 8rem 0rem 0rem 0rem;
    background: url("https://media.istockphoto.com/id/1370858067/vector/neutral-abstract-background-minimal-poster-template-with-diagonal-shapes-calm-design-with.jpg?s=612x612&w=0&k=20&c=18U2vCQ0oVYOi0uBiGUEGWaUWf4dgHKCURaJvRssIGI=")no-repeat center center/cover;
    background-attachment: fixed;
    padding: 5rem 5rem;
}

.section1{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: .7rem;
    margin:0rem 5rem 1rem 5rem; 
    text-align: center;
}

.section1 h2{
    width: 150px;
}

.section1 p {
    font-family: "Trirong", serif;
    font-size: 1.2rem;
}

.section2{
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   gap: 2rem;
}

.section2 textarea {
    width: 1050px;
    height: 10rem;
    padding: 1rem;
    font-family: "Trirong", serif;
    font-size: 1.1rem;
    background-color: transparent;
}

.section2 input {
    width: 1050px;
    height: 3rem;
    padding: 1rem;
    font-family: "Trirong", serif;
    font-size: 1.1rem;
    background-color: transparent;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid;
}

.section2 input:focus{
    outline: none;
}

.section2 Button{
    width: 20rem;
    height: 3.5rem;
    font-family: "Trirong", serif;
    font-size: 1.1rem;
}


@media screen and (max-width: 1200px) {
    .section2 input {
        width: 900px;
    }

    .section2 textarea {
        width: 900px;
    }
}


@media screen and (max-width: 1000px) {
    .section2 input {
        width: 700px;
    }

    .section2 textarea {
        width: 700px;
    }

    .section1 p {
        width: 700px;
    }
}


@media screen and (max-width: 800px) {
    .section2 input {
        width: 600px;
    }

    .section2 textarea {
        width: 600px;
    }

    .section1 p {
        width: 600px;
    }
}


@media screen and (max-width: 680px) {
    .section2 input {
        width: 500px;
    }

    .section2 textarea {
        width: 500px;
    }

    .section1 p {
        width: 500px;
    }
}


@media screen and (max-width: 570px) {
    .section2 input {
        width: 450px;
    }

    .section2 textarea {
        width: 450px;
    }

    .section1 p {
        width: 450px;
    }
}

@media screen and (max-width: 500px) {
    .section2 input {
        width: 350px;
    }

    .section2 textarea {
        width: 350px;
    }

    .section1 p {
        width: 350px;
    }
}

@media screen and (max-width: 400px) {
    .section2 input {
        width: 300px;
    }

    .section2 textarea {
        width: 300px;
    }

    .section1 p {
        width: 300px;
    }

    .section2 Button{
        width: 10rem;
    }
}

@media screen and (max-width: 350px) {
    .section2 input {
        width: 220px;
    }

    .section2 textarea {
        width: 220px;
    }

    .section1 p {
        width: 220px;
    }

    .section1{
        margin: 0rem 0rem 2rem 0rem;
    }
}