*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

.mainContainer{
    margin: 0rem 5rem 5rem 5rem;
    font-family: "Trirong", serif !important
}

@media screen and (width > 1400px) {
    .mainContainer{
        max-width: 1360px;
        margin: 10rem auto 5rem auto;
    }
}

@media screen and (max-width: 600px) {
    .mainContainer{
        margin: 10rem 2rem 5rem 2rem;
    }
}