* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

.mainContainer {
    margin: 10rem 5rem;
}

.section1 {
    display: flex;
    justify-content: center;
    text-align: center;
    font-family: "Trirong", serif;
}

.section1 span {
    color: #2992e7;
}

.section1 h1 {
    width: 600px;
}

.section2 {
    display: flex;
    flex-direction: row;
    margin: 5rem 5rem;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: center;
    align-items: center;
}


.box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30rem;
    height: 20rem;
    text-align: center;
    gap: 1rem;
    padding: 1rem;
    font-family: "Trirong", serif;
    font-size: 1.1rem;
    background-color: transparent !important;
    border-top-right-radius: 2rem !important;
    border-bottom-left-radius: 2rem !important;
}


.box :nth-child(1) {
    font-size: 2.5rem;
}

@media screen and (width > 1400px) {
    .mainContainer{
        max-width:1360px;
        margin: 10rem auto;
    }
}


@media screen and (max-width: 550px) {
    .box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 25rem;
        height: 22rem;
        text-align: center;
        gap: 1rem;
        padding: 1rem;
        font-family: "Trirong", serif;
        font-size: 1.1rem;
        background-color: transparent !important;
        border-top-right-radius: 2rem !important;
        border-bottom-left-radius: 2rem !important;
    }
}


@media screen and (max-width: 450px) {
    .box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 22rem;
        height: 22rem;
        text-align: center;
        gap: 1rem;
        padding: 1rem;
        font-family: "Trirong", serif;
        font-size: 1.1rem;
        background-color: transparent !important;
        border-top-right-radius: 2rem !important;
        border-bottom-left-radius: 2rem !important;
    }
}


@media screen and (max-width: 400px) {
    .box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: auto;
        text-align: center;
        gap: 1rem;
        padding: 0rem;
        font-family: "Trirong", serif;
        font-size: 1.1rem;
        background-color: transparent !important;
        box-shadow: none !important;
        border-top-right-radius: 2rem !important;
        border-bottom-left-radius: 2rem !important;
    }

    .section2 {
        display: flex;
        flex-direction: row;
        margin: 5rem 0rem;
        flex-wrap: wrap;
        gap: 5rem;
        justify-content: center;
        align-items: center;
    }

    .mainContainer {
        margin: 10rem 2rem;
    }
}