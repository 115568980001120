* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

.mainSection {
    padding: 5rem;
}

.section1 {
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.heading {
    width: 50vw;
    margin-top: 5rem;
    font-weight: 500;
    font-size: 2.5rem;
    padding-left: 1rem;
    color: #09518b;
}

.heading p:nth-child(2) {
    padding-top: 1rem;
    font-size: 1.5rem;
}

.heading p:nth-child(3) {
    padding-top: 1rem;
    font-size: 1.1rem;
    font-family: "Trirong", serif;
    width: 42vw;
    color: black;
}

.image {
    width: 50vw;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5rem;
}

.image img {
    width: 25rem;
    height: 20rem;
}

.cursor {
    font-size: 2rem;
}


@media screen and (max-width: 750px) {

    .mainSection {
        margin: 3rem 0rem 0rem 0rem;
    }

    .heading {
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        margin-top: 5rem;
        font-weight: 500;
        font-size: 2.5rem;
        color: #2992e7;
        text-align: center;
        padding-left: 0rem;
    }

    .section1 {
        display: flex;
        flex-direction: column;
    }

    .image {
        width: 50vw;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 2rem;
    }

    .heading p:nth-child(2) {
        padding-top: 1rem;
        font-size: 1.5rem;
    }

    .heading p:nth-child(3) {
        padding-top: 1rem;
        font-size: 1.1rem;
        font-family: "Trirong", serif;
        width: 100%;
        color: black;
    }

}



@media screen and (max-width: 400px) {

    .heading {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        margin-top: 5rem;
        font-weight: 500;
        font-size: 2.5rem;
        color: #2992e7;
        text-align: center;
    }

    .section1 {
        display: flex;
        flex-direction: column;
        margin: 0rem 1rem;
    }

    .image {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 2rem;
    }

    .image img {
        width: 100%;
        height: 100%;
    }

    .heading p:nth-child(1) {
        width: 100%;
        word-wrap: break-word;
    }

    .heading p:nth-child(2) {
        padding-top: 1rem;
        font-size: 1.5rem;
    }

    .heading p:nth-child(3) {
        padding-top: 1rem;
        font-size: 1.1rem;
        font-family: "Trirong", serif;
        width: 100%;
        color: black;
    }

}


@media screen and (width > 1400px) {
    .mainSection {
        max-width: 1360px;
        margin: 3rem auto;
    }
}