*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

.mainContainer{
    margin: 5rem 5rem 8rem 5rem;
    font-family: "Trirong", serif;
}

.section1{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;
    align-items: center;
    text-align: center;
}

.section1 span{
    color: #2992e7;
}

.section1 p{
    font-size: 1.1rem;
    width: 850px;
}

@media screen and (width > 1400px) {
    .mainContainer{
        max-width:1360px;
        margin: 5rem auto;
    }
}


@media screen and (max-width: 950px) {
    .section1 p {
        width: 800px;
    }
    .section1 h1 {
        width: 700px;
    }
}

@media screen and (max-width: 850px) {
    .section1 p {
        width: 700px;
    }
    .section1 h1 {
        width: 600px;
    }
}


@media screen and (max-width: 750px) {
    .section1 p {
        width: 600px;
    }
    .section1 h1 {
        width: 500px;
    }
}

@media screen and (max-width: 650px) {
    .section1 p {
        width: 500px;
    }
    .section1 h1 {
        width: 400px;
    }
}

@media screen and (max-width: 550px) {
    .section1 p {
        width: 400px;
    }
    .section1 h1 {
        width: 300px;
    }
}

@media screen and (max-width: 450px) {
    .section1 p {
        width: 300px;
    }
    .section1 h1 {
        width: 250px;
    }
}

@media screen and (max-width: 400px) {
    .section1 p {
        width: 100%;
    }
    .section1 h1 {
        width: 100%;
    }
    .mainContainer {
        margin: 5rem 2rem 8rem 2rem;
    }
}