* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

.section1 {
    font-family: "Trirong", serif;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    align-items: center;
    margin: 0rem 5rem;
}

.section1 h2 {
    color: black;
    width: 700px;
    text-align: center;
}

.section1 span {
    color: #2992e7;
}

.section1 p {
    width: 900px;
    text-align: center;
    font-size: 1.1rem;
}

.section2 {
    height: auto;
    margin: 3rem 0rem;
    padding: 3rem 5rem;
    text-align: center;
    background: url("https://media.istockphoto.com/id/1370858067/vector/neutral-abstract-background-minimal-poster-template-with-diagonal-shapes-calm-design-with.jpg?s=612x612&w=0&k=20&c=18U2vCQ0oVYOi0uBiGUEGWaUWf4dgHKCURaJvRssIGI=")no-repeat center center/cover;
    background-attachment: fixed;
}

.cardWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2rem;
}


.box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5rem !important;
    width: 35rem !important;
    height: 22rem !important;
    padding: 2rem !important;
    background-color: transparent !important;
    font-family: "Trirong", serif;
    font-size: 1.1rem;
}

.box h3 {
    color: #2992e7;
}

.box img {
    width: 8rem;
    height: 8rem;
}


.section3 {
    margin: 0rem 5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #2992e7;
    font-size: 1.3rem;
    font-weight: 700;
    font-family: "Trirong", serif;
}


@media screen and (width > 1400px) {
    .section1 {
        max-width: 1360px;
        margin: auto;
    }

    .cardWrapper {
        max-width: 1360px;
        margin: auto;
    }

    .section3 {
        max-width: 1360px;
        margin: auto;
    }
}


@media screen and (max-width: 950px) {
    .section1 p {
        width: 800px;
    }

    .section1 h2 {
        width: 600px;
    }
}

@media screen and (max-width: 850px) {
    .section1 p {
        width: 700px;
    }

    .section1 h2 {
        width: 500px;
    }
}

@media screen and (max-width: 750px) {
    .section1 p {
        width: 600px;
    }

    .section1 h2 {
        width: 400px;
    }
}

@media screen and (max-width: 700px) {
    .section1 p {
        width: 500px;
    }

    .section1 h2 {
        width: 350px;
    }

    .section3 {
        flex-direction: column;
        gap: 1rem;
    }
}

@media screen and (max-width: 590px) {
    .section1 p {
        width: 400px;
    }

    .section1 h2 {
        width: 350px;
    }

    .box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1.5rem !important;
        width: 100% !important;
        height: auto !important;
        padding: 0rem !important;
        background-color: transparent !important;
        font-family: "Trirong", serif;
        font-size: 1.1rem;
        box-shadow: none !important;
    }

    .cardWrapper {
        gap: 5rem;
    }

    .section2 {
        padding: 3rem 3rem;
    }

    .section3 {
        text-align: center;
    }
}

@media screen and (max-width: 400px) {
    .section1 p {
        width: 100%;
    }

    .section1 h2 {
        width: 100%;
    }

    .section1 {
        margin: 0rem 2rem;
    }
}