* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

.mainContainer {
    position: relative;
    margin: 5rem 0rem 0rem 0rem;
}

.img {
    width: 100%;
    height: 235px;
    object-fit: cover;
    opacity: 100;
}

.text {
    position: absolute;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;
}

.text p {
    font-weight: 700;
    font-size: 3rem;
    font-family: "Trirong", serif;
    color: white;
}

@media screen and (max-width:600px) {
    .text p {
        font-weight: 500;
        font-size: 2rem;
    }
}