* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

.mainContainer {
    margin: 8rem 5rem 8rem 5rem;
}

.section1 {
    font-family: "Trirong", serif;
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: 1.1rem;
}

.section2 {
    margin: 2rem 3rem 0rem 3rem;
}

.box {
    display: flex;
    flex-direction: column;
    gap: 1.5rem !important;
    width: 32rem !important;
    height: auto !important;
    padding: 2rem !important;
    background-color: transparent !important;
    font-family: "Trirong", serif;
    box-shadow: none !important;
    font-size: 1rem;
}

.box h3 {
    color: #2992e7;
}

.box span {
    color: #ffd832;
}

@media screen and (width > 1400px) {
    .mainContainer {
        max-width: 1360px;
        margin: 8rem auto;
    }
}

@media screen and (max-width: 1230px) {
    .box {
        display: flex;
        flex-direction: column;
        gap: 1.5rem !important;
        width: 28rem !important;
        height: auto !important;
        padding: 2rem !important;
        background-color: transparent !important;
        font-family: "Trirong", serif;
        box-shadow: none !important;
        font-size: 1rem;
    }
}

@media screen and (max-width: 1080px) {
    .box {
        display: flex;
        flex-direction: column;
        gap: 1.5rem !important;
        width: 25rem !important;
        height: auto !important;
        padding: 2rem !important;
        background-color: transparent !important;
        font-family: "Trirong", serif;
        box-shadow: none !important;
        font-size: 1rem;
    }
}

@media screen and (max-width: 1000px) {
    .box {
        display: flex;
        flex-direction: column;
        gap: 1.5rem !important;
        width: 25rem !important;
        height: auto !important;
        padding: 2rem !important;
        background-color: transparent !important;
        font-family: "Trirong", serif;
        box-shadow: none !important;
        font-size: 1rem;
    }

    .section2 {
        margin: 2rem 0rem 0rem 0rem;
    }
}

@media screen and (max-width: 910px) {
    .box {
        display: flex;
        flex-direction: column;
        gap: 1.5rem !important;
        width: 25rem !important;
        height: auto !important;
        padding: 2rem !important;
        background-color: transparent !important;
        font-family: "Trirong", serif;
        box-shadow: none !important;
        font-size: 1rem;
    }

    .mainContainer {
        margin: 8rem 2rem 8rem 2rem;
    }
}


@media screen and (max-width: 800px) {
    .box {
        display: flex;
        flex-direction: column;
        gap: 1.5rem !important;
        width: 35rem !important;
        height: auto !important;
        padding: 2rem !important;
        background-color: transparent !important;
        font-family: "Trirong", serif;
        box-shadow: none !important;
        font-size: 1rem;
    }

    .mainContainer {
        margin: 8rem 5rem 8rem 5rem;
    }
}


@media screen and (max-width: 690px) {
    .box {
        display: flex;
        flex-direction: column;
        gap: 1.5rem !important;
        width: 30rem !important;
        height: auto !important;
        padding: 2rem !important;
        background-color: transparent !important;
        font-family: "Trirong", serif;
        box-shadow: none !important;
        font-size: 1rem;
    }

    .mainContainer {
        margin: 8rem 5rem 8rem 5rem;
    }
}

@media screen and (max-width: 600px) {
    .box {
        display: flex;
        flex-direction: column;
        gap: 1.5rem !important;
        width: 25rem !important;
        height: auto !important;
        padding: 2rem !important;
        background-color: transparent !important;
        font-family: "Trirong", serif;
        box-shadow: none !important;
        font-size: 1rem;
    }

    .mainContainer {
        margin: 8rem 5rem 8rem 5rem;
    }
}


@media screen and (max-width: 530px) {
    .box {
        display: flex;
        flex-direction: column;
        gap: 1.5rem !important;
        width: 20rem !important;
        height: auto !important;
        padding: 2rem !important;
        background-color: transparent !important;
        font-family: "Trirong", serif;
        box-shadow: none !important;
        font-size: 1rem;
    }

    .mainContainer {
        margin: 8rem 5rem 8rem 5rem;
    }
}


@media screen and (max-width: 450px) {
    .box {
        display: flex;
        flex-direction: column;
        gap: 1.5rem !important;
        width: 22rem !important;
        height: auto !important;
        padding: 2rem !important;
        background-color: transparent !important;
        font-family: "Trirong", serif;
        box-shadow: none !important;
        font-size: 1rem;
    }

    .mainContainer {
        margin: 8rem 2rem 8rem 2rem;
    }
}


@media screen and (max-width: 380px) {
    .box {
        display: flex;
        flex-direction: column;
        gap: 1.5rem !important;
        width: 18rem !important;
        height: auto !important;
        padding: 2rem !important;
        background-color: transparent !important;
        font-family: "Trirong", serif;
        box-shadow: none !important;
        font-size: 1rem;
    }

    .mainContainer {
        margin: 8rem 2rem 8rem 2rem;
    }

    .section1 {
        text-align: center;
    }
}

@media screen and (max-width: 320px) {
    .box {
        display: flex;
        flex-direction: column;
        gap: 1.5rem !important;
        width: 14rem !important;
        height: auto !important;
        padding: 2rem !important;
        background-color: transparent !important;
        font-family: "Trirong", serif;
        box-shadow: none !important;
        font-size: 1rem;
    }

    .mainContainer {
        margin: 8rem 1rem 8rem 1rem;
    }
}