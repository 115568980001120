*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

.mainContainer{
    margin: 0rem 5rem;
}

.container{
    display: flex;
    justify-content: space-between;
}

.box{
    height: 15rem;
    width: 20rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 2rem;
    text-align: center;
    font-family: "Trirong", serif;
    font-size: 1.1rem;
}

@media screen and (width > 1400px) {
    .mainContainer{
        max-width:1360px;
        margin: 0rem auto 5rem auto;
    }
}

@media screen and (max-width: 1140px) {
    .container{
        display: flex;
        gap: 1rem;
        justify-content: center;
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 450px) {
    .box{
        height: auto;
        min-width: 20rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        padding: 2rem;
        text-align: center;
        font-family: "Trirong", serif;
        font-size: 1.1rem;
    }
}


@media screen and (max-width: 400px) {
    .box{
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        padding: 1rem;
        text-align: center;
        font-family: "Trirong", serif;
        font-size: 1.1rem;
        background: transparent !important;
        box-shadow: none !important;
    }

    .container{
        display: flex;
        gap: 3.5rem;
        justify-content: center;
        flex-wrap: wrap;
    }

    .mainContainer{
        margin: 7rem 0rem;
    }

}