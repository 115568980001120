* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

.mainContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10rem;
}

.section1 h2 span{
    color: #2992e7;
}

.horizontalRuleIcon{
    margin: 2.5rem 0rem;
    color: #2992e7;
    display: flex;
    justify-content: center;
    align-items: center;
}

.section1{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.section1 h2{
    color: black;
    width: 600px;
    text-align: center;
}

.section1 p{
    width: 600px;
    text-align: center;
    font-size: 1.1rem;
    font-family: "Trirong", serif;
}

.section2{
    margin: 5rem 0rem;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.box{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border-right: 1px solid;
    width: 18rem;
    height: 31rem;
    padding: 2rem;
    font-family: "Trirong", serif;
}

.box img{
    width: 3.7rem;
}

.box h3{
    color: #2992e7;
}


@media screen and (width > 1400px) {
    .mainSection {
        max-width: 1360px;
        margin: 3rem auto;
    }
}



@media screen and (max-width: 700px) {
    
    .section1 h2{
        color: black;
        width: 400px;
        text-align: center;
    }
    
    .section1 p{
        width: 500px;
        text-align: center;
        font-size: 1.1rem;
        font-family: "Trirong", serif;
    }

}


@media screen and (max-width: 615px) {

    .section2{
        margin: 5rem 0rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .box{
        display: flex;
        flex-direction: column;
        gap: 1rem;
        border:none;
        width: 25rem;
        height: 25rem;
        padding: 2rem;
        font-family: "Trirong", serif;
    }

}

@media screen and (max-width: 600px){

    .section1 h2{
        color: black;
        width: 300px;
        text-align: center;
    }
    
    .section1 p{
        width: 400px;
        text-align: center;
        font-size: 1.1rem;
        font-family: "Trirong", serif;
    }

}


@media screen and (max-width: 485px){

    .section1 h2{
        color: black;
        width: 270px;
        text-align: center;
    }
    
    .section1 p{
        width: 300px;
        text-align: center;
        font-size: 1.1rem;
        font-family: "Trirong", serif;
    }

}


@media screen and (max-width: 400px){

    .box{
        display: flex;
        flex-direction: column;
        gap: 1rem;
        border:none;
        width: 22rem;
        height: 25rem;
        padding: 2rem;
        font-family: "Trirong", serif;
    }

}


@media screen and (max-width: 350px){

    .section1 h2{
        color: black;
        width: 200px;
        text-align: center;
    }
    
    .section1 p{
        width: 250px;
        text-align: center;
        font-size: 1.1rem;
        font-family: "Trirong", serif;
    }

    .section2{
        margin: 5rem 0rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 5rem;
    }

    .box{
        display: flex;
        flex-direction: column;
        gap: 1rem;
        border:none;
        width: 18rem;
        height: 25rem;
        padding: 2rem;
        font-family: "Trirong", serif;
    }

}


@media screen and (max-width: 280px){

    .section1 h2{
        color: black;
        width: 150px;
        text-align: center;
    }
    
    .section1 p{
        width: 250px;
        text-align: center;
        font-size: 1.1rem;
        font-family: "Trirong", serif;
    }

    .section2{
        margin: 5rem 0rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 5rem;
    }

    .box{
        display: flex;
        flex-direction: column;
        gap: 1rem;
        border:none;
        width: 18rem;
        height: 25rem;
        padding: 2rem;
        font-family: "Trirong", serif;
    }

}



@media screen and (max-width: 260px){

    .section1 h2{
        color: black;
        width: 130px;
        text-align: center;
    }
    
    .section1 p{
        width: 210px;
        text-align: center;
        font-size: 1.1rem;
        font-family: "Trirong", serif;
    }

    .section2{
        margin: 5rem 0rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 10rem;
    }

    .box{
        display: flex;
        flex-direction: column;
        gap: 1rem;
        border:none;
        width: 15rem;
        height: 25rem;
        padding: 2rem;
        font-family: "Trirong", serif;
    }

}