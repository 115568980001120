*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

.mainContainer{
    margin: 0rem 5rem;
}


.section1 h1{
   display: flex;
   justify-content: center; 
   color: #2992e7;
   text-align: center;
}

.section2{
    display: flex;
    flex-direction: row;
    margin: 5rem 5rem;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: center;
    align-items: center;
}

.section2 h3{
    color: #2992e7;
}

.box{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 22rem;
    height: 26rem;
    text-align: center;
    gap: 1rem;
    padding: 1rem;
    font-family: "Trirong", serif;
    font-size: 1.1rem;
    border-top-right-radius: 2rem !important;
    border-bottom-left-radius: 2rem !important;
}

.box img{
    width: 8rem;
    height: 8rem;
}

@media screen and (width > 1400px) {
    .mainContainer{
        max-width:1360px;
        margin: 5rem auto;
    }
}

@media screen and (max-width: 1440px) {
    .section1 p{
        font-size: 1.1rem;
        width: 100%;
    }
    .section1 h1{
        width: 100%;
    }
    .box{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 20rem;
        height: 28rem;
        text-align: center;
        gap: 1rem;
        padding: 1rem;
        font-family: "Trirong", serif;
        font-size: 1.1rem;
        border-top-right-radius: 2rem !important;
        border-bottom-left-radius: 2rem !important;
    }

    .section2{
        display: flex;
        flex-direction: row;
        margin: 5rem 0rem;
        flex-wrap: wrap;
        gap: 4rem;
        justify-content: center;
        align-items: center;
    }

    .mainContainer{
        margin: 8rem 2rem;
    }
}



@media screen and (max-width: 400px) {
    .section1 p{
        font-size: 1.1rem;
        width: 100%;
    }
    .section1 h1{
        width: 100%;
    }
    .box{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;
        gap: 1rem;
        padding: 0rem;
        background: transparent !important;
        box-shadow: none !important;
    }

    .section2{
        display: flex;
        flex-direction: row;
        margin: 5rem 0rem;
        flex-wrap: wrap;
        gap: 4rem;
        justify-content: center;
        align-items: center;
    }

    .mainContainer{
        margin: 8rem 2rem;
    }
}