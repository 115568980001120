* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

.mainContainer {
    /* border: 1px solid; */
    min-height: 20rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 5rem;
    padding-top: 2rem;
    gap: 3rem;
    font-family: "Trirong", serif;
}

.link {
    text-decoration: none;
    color: black;
}

.wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0rem 5rem;
}

.ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.address {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.address span {
    font-weight: 700;
    color: #2992e7;
}

.section1 {
    width: 20rem;
    line-height: 1.8rem;
}

.section1 p {
    margin-top: 1rem;
}

.copyRight {
    margin: auto auto;
    margin-bottom: 1rem;
}

h2 {
    color: #2992e7;
}

.socialMediaLinks {
    display: flex;
    gap: .5rem;
    margin-top: .5rem;
}

.socialMediaIcons {
    color: #1a8ce9;
}

@media screen and (width > 1400px) {
    .mainContainer {
        max-width: 1800px;
        margin: 0rem auto;
    }
}


@media screen and (max-width: 700px) {

    .wrapper {
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }

    .mainContainer {
        width: 100%;
        min-height: 20rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 5rem;
        padding-top: 2rem;
        gap: 3rem;
    }

}

@media screen and (max-width: 700px) {

    .address p {
        word-break: break-all;
    }

    .ul li {
        word-break: break-all;
    }

}

@media screen and (max-width: 340px) {

    .section1 {
        width: 14rem;
    }

}

@media screen and (max-width: 280px) {

    .section1 {
        width: 12rem;
    }

}

@media screen and (max-width: 250px) {

    .copyRight {
        margin: auto auto;
        margin-bottom: 1rem;
        text-align: center;
    }

}