* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

.mainContainer {
    margin: 0rem 5rem 5rem 5rem;
}

.imgContainer {
    width: 15rem;
    height: 15rem;
    border-radius: 50%;
    overflow: hidden;
}

.section1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: "Trirong", serif;
    font-size: 2rem;
}

.heading {
    display: flex;
    flex-direction: column;
}

.heading h5 {
    color: #1a8ce9;
}

.section2 {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.5rem;
}

.box {
    display: flex !important;
    flex-direction: column;
    width: 18rem !important;
    height: 26rem !important;
    font-family: "Trirong", serif;
    padding-top: 2rem;
    align-items: center;
    border-radius: 2rem !important;
    gap: 1.5rem;
}

.nameAndDesignation {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.imgScale {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    object-fit: cover;
    transform: scale(2);
}

.img {
    width: 100%;
    height: 100%;
    border-radius: 100%;
}

.box h3 {
    color: #1a8ce9;
}

.socialMediaIcons {
    margin-top: .4rem;
    gap: .4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.link {
    color: #1a8ce9;
}

@media screen and (width > 1400px) {
    .mainContainer{
        max-width:1360px;
        margin: 0rem auto 5rem auto;
    }
}

@media screen and (max-width: 1330px) {
    .box {
        display: flex !important;
        flex-direction: column;
        width: 16rem !important;
        height: 24rem !important;
        font-family: "Trirong", serif;
        padding-top: 2rem;
        align-items: center;
        border-radius: 2rem !important;
        gap: 1.5rem;
    }

    .imgContainer {
        width: 12rem;
        height: 12rem;
    }
}


@media screen and (max-width: 1200px) {
    .box {
        display: flex !important;
        flex-direction: column;
        width: 14rem !important;
        height: 22rem !important;
        font-family: "Trirong", serif;
        padding-top: 2rem;
        align-items: center;
        border-radius: 2rem !important;
        gap: 1.5rem;
    }

    .imgContainer {
        width: 10rem;
        height: 10rem;
    }
}



@media screen and (max-width: 1065px) {
    .box {
        display: flex !important;
        flex-direction: column;
        width: 16rem !important;
        height: 24rem !important;
        font-family: "Trirong", serif;
        padding-top: 2rem;
        align-items: center;
        border-radius: 2rem !important;
        gap: 1.5rem;
    }

    .imgContainer {
        width: 12rem;
        height: 12rem;
    }
}

@media screen and (max-width: 900px) {
    .section1 {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        justify-content: space-between;
        align-items: center;
        font-family: "Trirong", serif;
        font-size: 2rem;
        word-break: keep-all;
        text-align: center;
    }
}


@media screen and (max-width: 935px) {
    .box {
        display: flex !important;
        flex-direction: column;
        width: 14rem !important;
        height: 22rem !important;
        font-family: "Trirong", serif;
        padding-top: 2rem;
        align-items: center;
        border-radius: 2rem !important;
        gap: 1.5rem;
    }

    .imgContainer {
        width: 10rem;
        height: 10rem;
    }
}


@media screen and (max-width: 835px) {
    .box {
        display: flex !important;
        flex-direction: column;
        width: 12rem !important;
        height: 20rem !important;
        font-family: "Trirong", serif;
        padding-top: 2rem;
        align-items: center;
        border-radius: 2rem !important;
        gap: 1.5rem;
    }

    .imgContainer {
        width: 8rem;
        height: 8rem;
    }
}


@media screen and (max-width: 750px) {
    .box {
        display: flex !important;
        flex-direction: column;
        width: 15rem !important;
        height: 23rem !important;
        font-family: "Trirong", serif;
        padding-top: 2rem;
        align-items: center;
        border-radius: 2rem !important;
        gap: 1.5rem;
    }

    .imgContainer {
        width: 11rem;
        height: 11rem;
    }
}


@media screen and (max-width: 650px) {
    .box {
        display: flex !important;
        flex-direction: column;
        width: 13rem !important;
        height: 21rem !important;
        font-family: "Trirong", serif;
        padding-top: 2rem;
        align-items: center;
        border-radius: 2rem !important;
        gap: 1.5rem;
    }

    .imgContainer {
        width: 9rem;
        height: 9rem;
    }
}

@media screen and (max-width: 600px) {
    .box {
        display: flex !important;
        flex-direction: column;
        width: 12rem !important;
        height: 18rem !important;
        font-family: "Trirong", serif;
        padding-top: 2rem;
        align-items: center;
        border-radius: 2rem !important;
        gap: 1.5rem;
    }

    .imgContainer {
        width: 7rem;
        height: 7rem;
    }
}


@media screen and (max-width: 550px) {
    .box {
        display: flex !important;
        flex-direction: column;
        width: 18rem !important;
        height: 24rem !important;
        font-family: "Trirong", serif;
        padding-top: 2rem;
        align-items: center;
        border-radius: 2rem !important;
        gap: 1.5rem;
    }

    .imgContainer {
        width: 12rem;
        height: 12rem;
    }
}


@media screen and (max-width: 460px) {
    .box {
        display: flex !important;
        flex-direction: column;
        width: 15rem !important;
        height: 22rem !important;
        font-family: "Trirong", serif;
        padding-top: 2rem;
        align-items: center;
        border-radius: 2rem !important;
        gap: 1.5rem;
    }

    .imgContainer {
        width: 10rem;
        height: 10rem;
    }
}


@media screen and (max-width: 405px) {
    .box {
        display: flex !important;
        flex-direction: column;
        width: 14rem !important;
        height: 20rem !important;
        font-family: "Trirong", serif;
        padding-top: 2rem;
        align-items: center;
        border-radius: 2rem !important;
        gap: 1.5rem;
    }

    .imgContainer {
        width: 8rem;
        height: 8rem;
    }

    .mainContainer {
        margin: 0rem 2rem 5rem 2rem;
    }
}

@media screen and (max-width: 290px) {
    .box {
        display: flex !important;
        flex-direction: column;
        width: 10rem !important;
        height: 16rem !important;
        font-family: "Trirong", serif;
        padding-top: 2rem;
        align-items: center;
        border-radius: 2rem !important;
        gap: 1.5rem;
    }

    .imgContainer {
        width: 6rem;
        height: 6rem;
    }

    .mainContainer {
        margin: 0rem 2rem 5rem 2rem;
    }

    .nameAndDesignation {
        font-size: .7rem;
    }
}


@media screen and (max-width: 248px) {
    .btn {
        width: 7rem !important;
    }
}