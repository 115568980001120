*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

.mainContainer{
    margin: 10rem 5rem;
    font-family: "Trirong", serif;
}

.section1{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 3rem;
}

.section1 h1{
    text-align: center;
}

.section1 span{
    color: #2992e7;
}

.section2{
    display: flex;
}

.image{
    width: 50vw;
}

.image img{
    width: 28rem;
    height: 25rem;
}

.description{
    width: 50vw;
    display: flex;
    flex-direction:column;
    justify-content: center;
    gap: .7rem;
}

@media screen and (width > 1400px) {
    .mainContainer{
        max-width:1360px;
        margin: 10rem auto;
    }
}


@media screen and (max-width: 1100px) {
    .section2 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .image{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media screen and (max-width: 570px) {
    .section2 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2rem;
    }
    .mainContainer{
        margin: 10rem 2rem;
        font-family: "Trirong", serif;
    }
    .image img{
        width: 100%;
        height: 100%;
    }
    .description{
        width: 100%;
    }
}