* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

.mainContainer {
    margin: 10rem 5rem;
}

.section1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
    font-family: "Trirong", serif;
    font-size: 1.1rem;
}

.heading {
    width: 50vw;
}

.imageContainer {
    width: 50vw;
    display: flex;
    flex-direction: row-reverse;
}

.heading h3 {
    color: #2992e7;
}

.imageContainer img {
    width: 20rem;
    height: 20rem;
}

.section2 {
    margin-top: 5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.box {
    width: 20rem;
    height: 15rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: center;
}

.box p {
    font-family: "Trirong", serif;
}

@media screen and (width > 1400px) {
    .section1 {
        max-width: 1360px;
        margin: auto;
    }

    .section2 {
        max-width: 1200px;
        margin: 5rem auto auto auto;
    }

}


@media screen and (max-width: 850px) {
    .section1 {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2.5rem;
        font-family: "Trirong", serif;
        font-size: 1.1rem;
    }

    .heading h3 {
        color: #2992e7;
        margin-bottom: 1rem;
    }

    .heading {
        width: 80vw;
    }

    .imageContainer {
        width: 80vw;
        display: flex;
        justify-content: center;
    }
}


@media screen and (max-width: 350px) {
    .imageContainer img {
        width: 100%;
        height: 100%;
    }
}