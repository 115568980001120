* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

.mainContainer {
    margin-top: 6rem;
}

.link {
    text-decoration: none;
}

.section1 {
    display: flex;
    justify-content: center;
}

.section1 h2 {
    width: 600px;
    text-align: center;
    color: black;
}

.section1 h2 span {
    color: #2992e7;
}


.section2 {
    height: auto;
    margin: 3rem 0rem;
    padding: 3rem 5rem;
    text-align: center;
    background: url("https://media.istockphoto.com/id/1370858067/vector/neutral-abstract-background-minimal-poster-template-with-diagonal-shapes-calm-design-with.jpg?s=612x612&w=0&k=20&c=18U2vCQ0oVYOi0uBiGUEGWaUWf4dgHKCURaJvRssIGI=")no-repeat center center/cover;
    background-attachment: fixed;
}

.box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5rem !important;
    width: 35rem !important;
    height: 20rem !important;
    padding: 2rem !important;
    background-color: transparent !important;
    font-family: "Trirong", serif;
    font-size: 1.1rem;
}

.cardWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2rem;
    text-align: center;
}

@media screen and (width > 1400px) {
    .cardWrapper{
        width: 1400px;
        margin: auto;
    }
}



@media screen and (max-width: 1100px) {

    .section1 h2 {
        width: 600px;
        text-align: center;
        color: black;
    }

}

@media screen and (max-width: 750px) {

    .section1 h2 {
        width: 550px;
        text-align: center;
        color: black;
    }

}

@media screen and (max-width: 600px) {

    .box {
        box-shadow: none !important;
        width: auto !important;
        padding: 0rem !important;
        height: auto !important;
    }

    .cardWrapper {
        gap: 4rem;
    }

    .section2{
        padding: 3rem 2rem;
    }

}

@media screen and (max-width: 550px) {

    .section1 h2 {
        width: 400px;
        text-align: center;
        color: black;
    }

}


@media screen and (max-width: 440px) {

    .section1 h2 {
        width: 320px;
        text-align: center;
        color: black;
    }

}


@media screen and (max-width: 350px) {

    .section1 h2 {
        width: 280px;
        text-align: center;
        color: black;
    }

}


@media screen and (max-width: 300px) {

    .mainContainer {
        margin: 6rem 0rem 0rem 0rem;
    }

    .section1 h2 {
        width: 100%;
        text-align: center;
        color: black;
    }

    .box {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 100%;
        height: auto;
        padding: 0rem;
        font-family: "Trirong", serif;
        border-radius: 0rem !important;
        background: transparent !important;
        box-shadow: none !important;
    }

    .cardWrapper {
        gap: 2rem;
    }

}