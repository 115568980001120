* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

.mainContainer {
    padding: 2rem;
    margin: 0rem 0rem 5rem 0rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
    text-align: center;
    background: url("https://media.istockphoto.com/id/1370858067/vector/neutral-abstract-background-minimal-poster-template-with-diagonal-shapes-calm-design-with.jpg?s=612x612&w=0&k=20&c=18U2vCQ0oVYOi0uBiGUEGWaUWf4dgHKCURaJvRssIGI=")no-repeat center center/cover;
    background-attachment: fixed;
}

.section1 {
    width: 650px;
    font-family: "Trirong", serif; 
    font-size: 1.2rem;
}

.section2 {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-size: 1.1rem;
    font-family: "Trirong", serif;
}

.section2 span {
    font-weight: 600;
    font-size: 1.2rem;
}

.inputAndbutton {
    display: flex;
    justify-content: center;
    align-items: center;
}

.section2 input {
    width: 500px;
    height: 3rem;
    padding: 1rem;
    font-size: 1.1rem;
    font-family: "Trirong", serif;
}

.section2 button {
    height: 3rem;
    border-radius: 0;
    box-shadow: none;
    font-size: 1.1rem;
    font-family: "Trirong", serif;
    background-color: #1a8ce9;
}

.section2 input:focus {
    outline: none;
}

.section2 button:hover {
    background-color: #1a8ce9;
    box-shadow: none;
}


@media screen and (max-width: 700px) {
    .section2 input {
        width: 400px;
        height: 3rem;
        padding: 1rem;
        font-size: 1.1rem;
        font-family: "Trirong", serif;
    }

    .section2 button {
        height: 3rem;
        border-radius: 0;
        box-shadow: none;
        font-size: 1.1rem;
        font-family: "Trirong", serif;
        background-color: #1a8ce9;
    }

    .section1 {
        width: 400px;
    }
}


@media screen and (max-width: 580px) {
    .section2 input {
        width: 300px;
        height: 3rem;
        padding: 1rem;
        font-size: 1.1rem;
        font-family: "Trirong", serif;
    }

    .section2 button {
        height: 3rem;
        border-radius: 0;
        box-shadow: none;
        font-size: 1.1rem;
        font-family: "Trirong", serif;
        background-color: #1a8ce9;
    }

    .section1 {
        width: 400px;
    }
}



@media screen and (max-width: 480px) {
    .section2 input {
        width: 250px;
        height: 3rem;
        padding: 1rem;
        font-size: 1.1rem;
        font-family: "Trirong", serif;
    }

    .section2 button {
        height: 3rem;
        border-radius: 0;
        box-shadow: none;
        font-size: 1.1rem;
        font-family: "Trirong", serif;
        background-color: #1a8ce9;
    }

    .section1 {
        width: 300px;
    }
}


@media screen and (max-width: 400px) {
    .section2 input {
        width: 100%;
        height: 3rem;
        padding: 1rem;
        font-size: 1.1rem;
        font-family: "Trirong", serif;
    }

    .section2 button {
        height: 3rem;
        border-radius: 0;
        box-shadow: none;
        font-size: .7rem;
        font-family: "Trirong", serif;
        background-color: #1a8ce9;
    }

    .section1 {
        width: 100%;
    }
}