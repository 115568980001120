*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

.mainContainer{
    margin: 8rem 0rem 8rem 0rem;
    font-family: "Trirong", serif;
}

.section1{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;
    align-items: center;
    text-align: center;
}

.section1 h1{
    width: 700px;
}

.section1 span{
    color: #2992e7;
}

.section1 p{
    font-size: 1.1rem;
    width: 1000px;
}

.section2 {
    height: auto;
    margin: 3rem 0rem;
    padding: 3rem 5rem;
    text-align: center;
    background: url("https://media.istockphoto.com/id/1370858067/vector/neutral-abstract-background-minimal-poster-template-with-diagonal-shapes-calm-design-with.jpg?s=612x612&w=0&k=20&c=18U2vCQ0oVYOi0uBiGUEGWaUWf4dgHKCURaJvRssIGI=")no-repeat center center/cover;
    background-attachment: fixed;
}

.cardWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2rem;
}

.box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5rem !important;
    width: 35rem !important;
    height: 26rem !important;
    padding: 2rem !important;
    background-color: transparent !important;
    font-family: "Trirong", serif;
    font-size: 1.1rem;
}

.box h3{
    color: #2992e7;
}

.box img{
    width: 8rem;
    height: 8rem;
}

.section3{
    margin: 0rem 12rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    align-items: center;
    border: 2px solid #2992e7;
    border-radius: 3rem;
    height: auto;
    font-family: "Trirong", serif;
    font-size: 1.1rem;
    padding: 3rem;
    text-align: center;
}

.section3 h2{
    color: black;
}

.section3 span{
    color: #2992e7;
}

@media screen and (width > 1400px) {
    .cardWrapper{
        max-width:1360px;
        margin: 0rem auto;
    }
    .section3{
        max-width:1360px;
        margin: 0rem auto 12rem auto;
    }
}


@media screen and (max-width: 1000px) {
    .section1 p {
        width: 800px;
        font-size: 1.1rem;
    }
}

@media screen and (max-width: 805px) {
    .section1 p {
        width: 700px;
        font-size: 1.1rem;
    }
    .section1 h1{
        width: 700px;
    }
    .section3{
        margin: 0rem 2rem;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        justify-content: center;
        align-items: center;
        border: none;
        border-radius: 3rem;
        height: auto;
        font-family: "Trirong", serif;
        padding: 0rem;
        text-align: center;
    }
}

@media screen and (max-width: 715px) {
    .section1 p {
        width: 600px;
        font-size: 1.1rem;
    }
    .section1 h1{
        width: 600px;
    }
}

@media screen and (max-width: 600px) {
    .section1 p {
        width: 500px;
        font-size: 1.1rem;
    }
    .section1 h1{
        width: 500px;
    }
    .box{
        box-shadow: none !important;
        width: auto !important;
        padding: 0rem !important;
        height: auto !important;
    }
    .cardWrapper {
        gap: 4rem;
    }

    .section2{
        padding: 3rem 2rem;
    }
}

@media screen and (max-width: 500px) {
    .section1 p {
        width: 100%;
        font-size: 1.1rem;
    }
    .section1 h1{
        width: 100%;
    }
    .section1 {
        margin: 0rem 2rem;
    }
}