* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

.mainContainer {
    margin: 5rem 0rem;
}

.section1 {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: "Trirong", serif;
}

.section1 h1 {
    width: 600px;
    color: black;
}

.section1 span {
    color: #2992e7;
}

.section1 p {
    font-size: 1.1rem;
    width: 1000px;
}

.section2 {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 3rem;
    gap: 5rem;
    font-family: "Trirong", serif;
    font-size: 1.1rem;
}

.box {
    display: flex;
    flex-direction: column;
    width: 30rem;
    height: 24rem;
    gap: 1rem;
    padding: 2.5rem;
    background: url("https://img.freepik.com/free-vector/blue-white-gradient-abstract-background_53876-60241.jpg");
}

@media screen and (width > 1400px) {
    .mainContainer{
        max-width:1360px;
        margin: 5rem auto;
    }
}



@media screen and (max-width: 1000px) {
    .section1 p {
        font-size: 1.1rem;
        width: 800px;
    }
}


@media screen and (max-width: 900px) {
    .section1 p {
        font-size: 1.1rem;
        width: 600px;
    }
}

@media screen and (max-width: 700px) {
    .section1 p {
        font-size: 1.1rem;
        width: 500px;
    }

    .section1 h1 {
        width: 550px;
    }
}

@media screen and (max-width: 550px) {
    .section1 p {
        font-size: 1.1rem;
        width: 400px;
    }

    .section1 h1 {
        width: 500px;
    }

    .box {
        display: flex;
        flex-direction: column;
        width: 25rem;
        height: auto;
        gap: 1rem;
        padding: 2.5rem;
        background: url("https://img.freepik.com/free-vector/blue-white-gradient-abstract-background_53876-60241.jpg");
    }
}


@media screen and (max-width: 500px) {
    .section1 p {
        font-size: 1.1rem;
        width: 300px;
    }

    .section1 h1 {
        width: 350px;
    }

    .box {
        display: flex;
        flex-direction: column;
        width: 22rem;
        height: auto;
        gap: 1rem;
        padding: 2.5rem;
        background: url("https://img.freepik.com/free-vector/blue-white-gradient-abstract-background_53876-60241.jpg");
    }
}


@media screen and (max-width: 400px) {

    .section1 p {
        font-size: 1.1rem;
        width: 100%;
    }

    .section1 h1 {
        width: 100%;
    }

    .box {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;
        gap: 1rem;
        padding: 0rem;
        background: transparent !important;
        box-shadow: none !important;
    }

    .section2 {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-top: 3rem;
        gap: 3rem;
        font-family: "Trirong", serif;
        font-size: 1.1rem;
    }
    .mainContainer{
        margin: 5rem 2rem;
    }
}